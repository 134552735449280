<template>
	<div>
		<active-title class="protect_user_title">
			<span v-text="`被保人列表(${userList.length}/200)`"></span>
			<div class="btns" v-if="needBtn">
				<div class="user_same name_paste" @click="openNamePasteDialog">名单粘贴</div>
				<div class="user_same" v-if="showSame" @click="sameTbr">同投保人</div>
			</div>
		</active-title>
		<van-cell-group :border="false" v-show="userList.length">
			<van-cell v-for="(item, index) in userList" :key="index" @click="editUser(item)" title-class="cell_title" value-class="cell_value" class="user_info_cell" :border="true" is-link>
				<span class="user_icon" slot="icon" @click.stop="deleteUser(item.id)"></span>
				<div class="user_title" slot="title">
					{{ item.name }}<span class="price-css">{{ $base.floatPrice(item.price) }}</span>
				</div>
				<span slot="default" class="user_list_error_text">
					<span v-if="item.isError">信息中有误</span>
					<span v-if="item.isError && item.isRepeat">，</span>
					<span v-if="item.isRepeat">证件号码重复</span>
				</span>
			</van-cell>
		</van-cell-group>

		<div class="add_user_btn" @click="addUser" v-if="this.userList.length < 200">
			<span class="icon"></span>
			<span class="text">新增被保险人</span>
		</div>
		<namePaste :show.sync="namePasteDialogShow" :hasEnglishName="hasEnglishName" :dataLength="userList.length" @update:show="val => (namePasteDialogShow = val)" @setUserInfo="setUserInfo" />
		<van-button type="primary" @click="getData" class="none" >获取数据</van-button>
	</div>
</template>

<script>
import { Cell, CellGroup, Button, Toast } from 'vant';
import namePaste from './namePaste.vue';
import regular from '@/assets/js/regular';
import Mtils from 'mtils';
import { http_insTranslate } from '@/request/common';
import validations from '@/assets/js/validations.js';

export default {
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Button.name]: Button,
		namePaste,
	},
	props: {
		group: {
			type: Object,
			default: () => {},
		},
		needBtn: {
			type: Boolean,
			default: true,
		},
		backPath: {
			type: String,
			default: 'createOrderV2',
		},
	},
	data() {
		return {
			userList: [],
			namePasteDialogShow: false,
			fields: ['name', 'englishName', 'certificateType', 'number', 'birthday', 'phone', 'sex', 'price', 'profession'],

			// 被保人信息校验规则
			rules: {
				name: [
					{ required: true, trigger: 'onBlur' },
					{
						pattern: regular.name1,
						message: '只能输入中文或英文 以及·',
					},
					{
						pattern: regular.name2,
						message: '至少2个中文或4个英文字母',
					},
					{
						validator: this.nameCheck,
						trigger: 'onChange',
						message: '·不能出现在首尾',
					},
				],
				englishName: [{ required: true, trigger: 'onBlur' }],
				certificateType: [{ required: true, trigger: 'onBlur' }],
				number: [
					{ required: true, trigger: 'onBlur' },
					{
						validator: this.insuredCardNumCheck,
						trigger: 'onBlur',
						message: '证件号码有误',
					},
				],
				birthday: [
					{ required: true, trigger: 'onChange' },
					{
						validator: this.ageEnableCheck,
						trigger: 'onChange',
						message: '年龄不符合承保范围',
					},
				],
				sex: [{ required: true, trigger: 'onChange' }],
				phone: [
					{
						validator: this.phoneCheck,
						trigger: 'onBlur',
						message: '手机号码有误',
					},
				],
				profession: [{ required: true, trigger: 'onChange' }],
			},
		};
	},
	computed: {
		// 是否有英文名称，根据险种来判断，境外险就有
		hasEnglishName() {
			if (this.group.insForm2VoList) {
				return this.group.insForm2VoList.some(item => item.fieldName === 'englishName');
			}
			return false;
		},
		//是否有职业
		hasProfession() {
			if (this.group.insForm2VoList) {
				return this.group.insForm2VoList.some(item => item.fieldName === 'profession');
			}
			return false;
		},
		showSame() {
			return this.$store.state.insuranceV2.showCommon == 1;
		},
		// 证件类型
		cardTypeList() {
			let result = [];
			if (this.group.insForm2VoList) {
				let cardTypeFieldList = this.group.insForm2VoList.filter(item => item.fieldName === 'certificateType');
				if (cardTypeFieldList.length) {
					let fieldInfo = cardTypeFieldList[0];
					let enableValues = JSON.parse(fieldInfo.optionalValue);
					result = this.$store.state.insuranceV2.codeList
						.filter(item => item.category === fieldInfo.optionalGroup && enableValues.includes(item.commonCode))
						.map(v => {
							return { name: v.commonCode, label: v.commonDeclare };
						});
				}
			}
			return result;
		},
		professionList() {
			let result = [];
			let professionFieldList = this.group.insForm2VoList.filter(item => item.fieldName === 'profession');
			if (professionFieldList.length) {
				const storeData = this.$store.state.insuranceV2;
				const careerData = this.filterTree(storeData.careerList, JSON.parse(professionFieldList[0].optionalValue));
				result = this.getCascader(careerData, { name: 'professionName', code: 'professionCode' });
			}

			return result;
		},
	},
	watch: {
		'$route': {
			handler: function (route) {
				let params = route.params;
				if (params.hasOwnProperty('info')) {
					this.setUserInfo(params.info);
				}
			},
			deep: true,
		},
		'$store.state.insuranceV2.priceId'() {
			this.userList.length &&
				this.userList.forEach(item => {
					this.changePrice(item);
				});
			this.setTotalPrice();
		},
		'$store.state.insuranceV2.days'() {
			this.userList.length &&
				this.userList.forEach(item => {
					this.changePrice(item);
				});
			this.setTotalPrice();
		},
	},
	methods: {
		// 删除被保人
		deleteUser(id) {
			let ids = this.userList.map(item => item.id);
			let index = ids.indexOf(id);
			this.userList.splice(index, 1);

			// 执行重复检查
			this.validateRepeat();
			this.setTotalPrice();
		},
		// 编辑被保人
		editUser(info) {
			this.$store.commit('set_createOrderRouteName', this.backPath);
			let routerName = 'addUserV2';
			if (this.backPath.indexOf('cps') !== -1) {
				routerName = 'cpsAddUserV2';
			}
			this.$router.push({
				name: routerName,
				params: {
					hasEnglishName: this.hasEnglishName,
					cardTypeList: this.cardTypeList,
					professionList: this.professionList,
					info,
					hasProfession: this.hasProfession,
				},
			});
		},

		// 把字符串转化为正则对象
		stringToRegExp(patternString) {
			let lastCharacter = patternString.charAt(patternString.length - 1);
			if (['i', 'g', 'm'].includes(lastCharacter)) {
				return new RegExp(patternString.slice(1, patternString.length - 2), lastCharacter);
			} else {
				return new RegExp(patternString.slice(1, patternString.length - 1));
			}
		},
		// 新增被保人
		addUser() {
			this.$store.commit('set_createOrderRouteName', this.backPath);
			let routerName = 'addUserV2';
			if (this.backPath.indexOf('cps') !== -1) {
				routerName = 'cpsAddUserV2';
			}
			this.$router.push({
				name: routerName,
				params: {
					hasEnglishName: this.hasEnglishName,
					professionList: this.professionList,
					cardTypeList: this.cardTypeList,
					hasProfession: this.hasProfession,
				},
			});
		},
		// 打开名单粘贴弹窗
		openNamePasteDialog() {
			this.namePasteDialogShow = true;
		},

		// 手动校验被保险人信息
		chekUserInfoList() {
			this.userList.forEach(dict => {
				dict.isError = !this.checkUserInfo(dict);
			});
		},
		// 检查重复的证件号码，该方法回标记出所有的重复信息，比如：有三条一模一样的证件号码，会把这三条全标记出来
		validateRepeat(cb) {
			let repeatInfos = [];
			let idNumList = this.userList.map(row => row.number);
			let repeatIds = [];
			for (let i = 0; i < idNumList.length; i++) {
				const num = idNumList[i];
				let otherNums = idNumList.slice(i + 1);
				let repeatIndex = otherNums.indexOf(num);
				if (repeatIndex !== -1) {
					repeatIds.push(num);
					this.userList[i].isRepeat = true;
					this.userList[i + repeatIndex + 1].isRepeat = true;
					repeatInfos.push(this.userList[i], this.userList[i + repeatIndex + 1]);
				} else {
					if (!repeatIds.includes(num)) {
						this.$set(this.userList[i], 'isRepeat', false);
					}
				}
			}
			if (cb) {
				cb(repeatInfos);
			}
		},
		// 校验被保险人信息方法，有错误即停止检查，并返回false。完全正确返回true
		checkUserInfo(info) {
			let result = true;
			outer: for (const key in this.rules) {
				if (Object.hasOwnProperty.call(this.rules, key)) {
					const val = info[key];
					let ruleList = this.rules[key];

					// 注意：break无法跳出foreach
					for (let i = 0; i < ruleList.length; i++) {
						const rule = ruleList[i];
						// 必填校验
						if (Object.hasOwnProperty.call(rule, 'required') && key !== 'phone' && (!this.hasEnglishName ? key !== 'englishName' : true) && (!this.hasProfession ? key !== 'profession' : true)) {
							if (rule.required && !val && val !== 0) {
								result = false;

								break outer;
							}
						}

						// 自定义方法校验
						if (Object.hasOwnProperty.call(rule, 'validator')) {
							if (rule.validator && (val || val === 0)) {
								if (rule.validator(val, info) !== true) {
									result = false;
									break outer;
								}
							}
						}
					}
				}
			}
			return result;
		},

		// 校验规则-被保人姓名
		nameCheck(val) {
			if (val[0] === '·' || val[val.length - 1] === '·') {
				return false;
			}
			return true;
		},
		// 校验规则-被保险人证件号码
		insuredCardNumCheck(val, info) {
			// 身份证
			if (info.certificateType === '01') {
				return Mtils.validation.isIdCard(val);
			}
			//   护照
			if (info.certificateType === '02') {
				return regular.passport.test(val) && !regular.repeatReg7.test(val) && !regular.sequentialReg7.test(val);
			}
			//   军官证
			if (info.certificateType === '03') {
				return regular.army.test(val);
			}
			//港澳回乡证
			if (info.certificateType === '05') {
				return regular.hkCard.test(val);
			}
			//台胞证
			if (info.certificateType === '07') {
				return regular.twCard.test(val);
			}
			return true;
		},
		// 校验规则-被保人年龄是否符合产品投保要求
		ageEnableCheck(val) {
			let age = this.$base.getAgeFromStartTime(val,this.$store.state.insuranceV2.startTime);
			const { protectAgeMin: min, protectAgeMax: max } = this.$store.state.insuranceV2;
			if ((min !== undefined && age < min) || (max !== undefined && age > max)) {
				return false;
			}
			return true;
		},
		// 校验手机号码
		phoneCheck(val) {
			if (!val) {
				return true;
			}
			return regular.phone.test(val);
		},
		// 新增被保险人-设置数据 idCompensation: id补偿字符，因为循环太快，在1ms中可以计算很多数据，id会重复
		setUserInfo(info, idCompensation = undefined) {
			this.changePrice(info);

			if (!info.id) {
				info.id = String(Date.now());
				if (idCompensation !== undefined) {
					info.id += idCompensation;
				}
				this.userList.push(info);
			} else {
				this.userList.forEach(item => {
					if (item.id === info.id) {
						Object.assign(item, info);
					}
				});
			}
			// 执行校验方法
			this.chekUserInfoList();
			// 执行重复检查
			this.validateRepeat();
			// 刷新DOM
			this.$forceUpdate();
			this.setTotalPrice();
		},
		// 同投保人
		sameTbr() {
			this.$emit('getInsureForm', this.sameTbrData);
		},
		async sameTbrData(data) {
			let applicantForm = JSON.parse(JSON.stringify(data));
			let addObj = {
				name: applicantForm.insName,
				certificateType: applicantForm.insCredentialsType,
				number: applicantForm.insCredentials,
				birthday: applicantForm.insBirthday,
				sex: applicantForm.insSex,
				phone: applicantForm?.insPhone,
				englishName: applicantForm?.insEnglishName,
				id: undefined,
			};

			if (this.hasEnglishName) {
				if (addObj.name && !addObj.englishName) {
					const res=await http_insTranslate([String(addObj.name)])
					addObj.englishName = res[String(addObj.name)];
				}
			}

			if (!this.userList.some(item => item.number === applicantForm.insCredentials)) {
				this.setUserInfo(addObj);
			} else {
				const id = this.userList.filter(item => item.number === applicantForm.insCredentials)[0].id;
				this.setUserInfo({ ...addObj, id });
			}
		},
		//计算价格
		changePrice(dict) {
			const { insProductPrice2VoList } = this.$store.state.insuranceV2;
			const data = insProductPrice2VoList;
			if (!data?.price) return;
			dict.price = data.price;
			const price = data.price;

			if (data.rateType === 1) {
				let rateCalculationExpression = data.rateCalculationExpression;
				const age = this.$base.getAgeFromStartTime(dict.birthday,this.$store.state.insuranceV2.startTime);
				const sex = dict.sex;
				const profession = dict.profession;
				const matches = Array.from(new Set(rateCalculationExpression.match(/\b[A-Za-z]\w*/g))); //获取表达式中的字段
				//跟扩展字段有关，后续补上
				const extentionVoList = this.$store.state.insuranceV2.allForm;
				//把对应的扩展字段的值替换掉
				for (let i in matches) {
					if (extentionVoList.hasOwnProperty(matches[i]) && ((this.hasProfession && matches[i] != 'profession')||!this.hasProfession)) {
						rateCalculationExpression = rateCalculationExpression.replace(new RegExp('\\b' + matches[i] + '\\b', 'g'), `extentionVoList.${matches[i]}`);
					}
				}
				this.$set(dict, 'price', eval(rateCalculationExpression));
			}
		},
		// 校验所有数据
		validateAssured() {
			return new Promise(resolve => {
				// 执行校验方法
				this.chekUserInfoList();
				// 执行重复检查
				this.validateRepeat();
					// 刷新DOM
				this.$forceUpdate();
				if (this.userList.some(item => item.isError || item.isRepeat)) {
					Toast('请检查投保信息');
				} else {
					resolve();
				}
			});
		},
		// 获取数据
		getData() {
			return new Promise(resolve => {
				this.validateAssured().then(() => {
					// 过滤掉无需传出的字段
					let result = this.userList.map(item => {
						let dict = {};
						for (const key in item) {
							if (Object.hasOwnProperty.call(item, key) && this.fields.includes(key)) {
								const val = item[key];
								dict[key] = val;
							}
							if(isNaN(item[key])&&!isNaN(Date.parse(item[key]))){
								dict[key]=item[key].replace(/\//g, '-')
							}
						}
						return dict;
					});
					let data = { assured: result };
					resolve(data);
				});
			});
		},
		// 计算总价格
		setTotalPrice() {
			let total = 0;
			total = this.userList.reduce((total, item) => {
				return (total += item.price*1000);
			}, 0);
			this.$store.commit('setAllPrice', total/1000);
		},

		// 处理级联选择器
		getCascader(obj, fieldName) {
			// 检查对象是否为数组
			if (Array.isArray(obj)) {
				for (let i = 0; i < obj.length; i++) {
					obj[i] = this.getCascader(obj[i], fieldName);
				}
			} else if (typeof obj === 'object' && obj !== null) {
				for (let key in obj) {
					if (obj.hasOwnProperty(key)) {
						if (key === fieldName['name']) {
							obj['name'] = obj[key];
							delete obj[key];
						} else if (key === fieldName['code']) {
							obj['code'] = obj[key];
							delete obj[key];
						} else {
							obj[key] = this.getCascader(obj[key], fieldName);
						}
					}
				}
			}
			return obj; // 返回替换后的对象
		},

		// 过滤数据
		filterTree(treeData, codeList) {
			let t = JSON.parse(JSON.stringify(treeData));
			function traverseTree(tree, codes, parent = null) {
				for (let i = tree?.length - 1; i >= 0; i--) {
					const node = tree[i];
					if (node?.children && node.children.length) {
						// 存在子节点
						// 继续判断子节点
						traverseTree(node.children, codes, tree);
					} else {
						if (!codes.includes(node?.professionCode)) {
							tree.splice(i, 1);
							traverseTree(parent, codes);
						}
					}
				}
			}
			traverseTree(t, codeList);

			return t;
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .protect_user_title .text {
	display: block;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;
	.user_same {
		padding: 0 0.2rem;
		font-size: 0.24rem;
		line-height: 0.5rem;
		text-align: center;
		border-radius: 0.08rem;
		border: 1px solid $color_main;
		color: #fff;
		background-color: $color_main;
	}
	.btns {
		display: flex;
		justify-content: space-between;
		.name_paste {
			margin-right: 0.2rem;
		}
	}
}
.user_info_cell {
	align-items: center;
}
.user_icon {
	width: 0.28rem;
	height: 0.28rem;
	background: url(../../../assets/icon/delete.png);
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center;
	margin-right: 0.16rem;
}
.user_icon,
.user_title {
	font-size: 0.28rem;
}
.add_user_btn {
	margin: 0.5rem 0.3rem 0.4rem;
	height: 0.9rem;
	border: 1px dashed #dddddd;
	display: flex;
	align-items: center;
	justify-content: center;
	.icon {
		width: 0.23rem;
		height: 0.22rem;
		background: url(../../../assets/icon/add.png);
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: center;
	}
	.text {
		font-size: 0.3rem;
		color: $color_main;
		margin-left: 0.2rem;
	}
}
.user_list_error_text {
	color: $danger;
}
.price-css {
	color: $danger;
	margin-left: 0.1rem;
}
.none {
	display: none;
}
</style>
